import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import vuetify from "./plugins/vuetify";

// create pinia - https://pinia.vuejs.org/
import { createPinia } from "pinia";

const pinia = createPinia();

// const app = createApp(App).use(router).use(vuetify).use(pinia).mount("#app");
createApp(App).use(router).use(vuetify).use(pinia).mount("#app");
