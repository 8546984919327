/* eslint-disable */

// Automatically Generated file from Excelfile "C:/Users/sebas/Pressure Control Solutions/Pressure Control Solutions - tabellen ed/modbus adress space(pod V1) with macro's.xlsm"
// Generated at at 2021-11-09-16:34:05

import { read_csv_file } from "@/lib/csv";

export type ReadOnlyType = "True" | "False";

export type InputType =
  | "dropdown"
  | "text"
  | "number"
  | "checkbox"
  | "radio"
  | "slider"
  | "switch";

export type UnitType =
  | "pressure"
  | "Volumetric Flow Rate"
  | "density"
  | "volume"
  | "Temperature"
  | "time"
  | "number"
  | "string"
  | "bool"
  | "frequency"
  | "partsPer"
  | "current"
  | "voltage"
  | "date-time"
  | "ref_unit_type"
  | "ref_unit"
  | "Gauge pressure"
  | "Differential pressure"
  | "Absolute pressure"
  | "Level"
  | "unit"
  | "default_unit"
  | "Mass Flow Rate";

export type AccessLevelType = "normal(1)" | "medium(2)" | "full(3)";

export type DataType =
  | "float"
  | "int"
  | "uint32_t"
  | "char2"
  | "uint16_t"
  | "bool";

export type UnitSystem = "Metric" | "SI" | "Imperial" | "Other";

export interface DefaultUnitsFile {
  pressure: string;
  "Volumetric Flow Rate": string;
  Level: string;
  Temperature: string;
  humidity: string;
  current: string;
  voltage: string;
  "Gauge pressure": string;
  "Absolute pressure": string;
  "Differential pressure": string;
  partsPer: string;
  frequency: string;
}

export interface ModbusParam {
  "mbus address": string;
  name: string;
  type: DataType | "0" | null;
  "value on clean boot": string;
  min: string;
  max: string;
  default: string;
  "read-only": string;
  "flash address": string;
  "in code name": string;
  "write access": AccessLevelType | "0" | null;
  user_info: string;
  group: string;
  sub_group: string;
  input_type: InputType | "0" | null;
  input_info: string;
  unit_type: UnitType | "0" | null;
  unit: string;
  min_ref: string;
  max_ref: string;
}

export async function get_params_csv(): Promise<ModbusParam[] | null> {
  return read_csv_file<ModbusParam>("./data/params.csv");
}

export interface DeviceUnitItem {
  unit_type: UnitType;
  device_unit: string;
}

export async function get_device_units_csv(): Promise<DeviceUnitItem[] | null> {
  return read_csv_file<DeviceUnitItem>("./data/device_units.csv");
}

export interface UnitItem {
  name: string;
  "No.": string;
  unit_type: UnitType;
  Symbol: UnitSymbol;
  "Lib Key": string;
  system: UnitSystem;
  enabled: string;
  systembase: string;
  offset: string;
  factor: string;
  Notes: string;
}

export async function get_units_gui_csv(): Promise<UnitItem[] | null> {
  return read_csv_file<UnitItem>("./data/units_gui.csv");
}

export type GaugePressureSymbol =
  | "barg"
  | "mbarg"
  | "psig"
  | "kPag"
  | "hPag"
  | "Mpag"
  | "Pag"
  | "kgf/cm2g"
  | "gf/cm2g";

export type AbsolutePressureSymbol =
  | "bara"
  | "mbara"
  | "psia"
  | "kPaa"
  | "hPa"
  | "Mpa"
  | "Paa"
  | "kgf/cm2a"
  | "gf/cm2a";

export type DifferentialPressureSymbol =
  | "bard"
  | "mbard"
  | "psid"
  | "kPad"
  | "hPad"
  | "Mpad"
  | "Pad"
  | "kgf/cm2d"
  | "gf/cm2d";

export type TemperatureSymbol = "�C�" | "�F" | "K";

export type VolumetricFlowRateSymbol =
  | "mls/s"
  | "mln/s"
  | "mls/min"
  | "mln/min"
  | "mls/h"
  | "mln/h"
  | "cls/s"
  | "cln/s"
  | "cls/min"
  | "cln/m"
  | "cls/h"
  | "cln/h"
  | "dls/s"
  | "dln/s"
  | "dls/min"
  | "dln/min"
  | "dls/h"
  | "dln/h"
  | "ls/s"
  | "ln/s"
  | "ls/min"
  | "ln/min"
  | "ls/h"
  | "ln/h"
  | "mm�s/s"
  | "mm�n/s"
  | "mm�s/min"
  | "mm�n/min"
  | "mm�s/h"
  | "mm�/h"
  | "cm�s/s"
  | "cm�n/s"
  | "cm�s/min"
  | "cm�n/min"
  | "cm�s/h"
  | "cm�n/h"
  | "m�s/s"
  | "m�n/s"
  | "m�s/min"
  | "m�n/min"
  | "m�s/h"
  | "m�n/h"
  | "sccm"
  | "s fl.oz/s"
  | "n fl.oz/s"
  | "s fl.oz/min"
  | "n fl.oz/min"
  | "s fl.oz/h"
  | "n fl.oz/h"
  | "s gal/s"
  | "n gal/s"
  | "s gal/min"
  | "n gal/min"
  | "s gal/hour"
  | "n gal/hour"
  | "scfs"
  | "scfm"
  | "scfh";

export type TimeSymbol = "min" | "s" | "ms" | "us" | "h";

export type VolumeSymbol =
  | "cc"
  | "ml"
  | "cm3"
  | "cl"
  | "dl"
  | "l"
  | "hl"
  | "dm3"
  | "m3"
  | "gal"
  | "fl. oz.";

export type LevelSymbol = "%";

export type VoltageSymbol = "V" | "kV" | "mV";

export type CurrentSymbol = "A" | "mA";

export type FrequencySymbol = "Hz" | "KHz" | "MHz" | "GHz";

export type PartsperSymbol = "%" | "�" | "ppm" | "ppt";

export type UnitSymbol =
  | GaugePressureSymbol
  | AbsolutePressureSymbol
  | DifferentialPressureSymbol
  | TemperatureSymbol
  | VolumetricFlowRateSymbol
  | TimeSymbol
  | VolumeSymbol
  | LevelSymbol
  | VoltageSymbol
  | CurrentSymbol
  | FrequencySymbol
  | PartsperSymbol;

export function get_modbus_data_len(_type: DataType | "0" | null) {
  let len = 0;
  if (_type == "float" || _type == "int" || _type == "uint32_t") {
    len = 2;
  }
  if (_type == "uint16_t" || _type == "bool") {
    len = 1;
  }
  return len;
}
