import { UnitSymbol } from "./gen_interfaces";

export interface DashboardRange {
  color: string;
  from: number;
  to: number;
}

export interface DashboardItem {
  modbusAddress: number;
  name: string;
  unit: UnitSymbol;
  unit_items: string[];
  valueRange: { min: number; max: number };
  ranges: DashboardRange[];
  ticks: number[];
  factor?: number;
  offset?: number;
}

export const dashboardItems: DashboardItem[] = [
  {
    modbusAddress: 50,
    name: "Device Temperature",
    unit: "°C" as UnitSymbol,
    valueRange: { min: -20, max: 100 },
    ranges: [
      { color: "red", from: -20, to: -10 },
      { color: "yellow", from: -10, to: 0 },
      { color: "green", from: 0, to: 60 },
      { color: "yellow", from: 60, to: 70 },
      { color: "red", from: 70, to: 100 },
    ],
    ticks: [-20, -10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    unit_items: ["Celcius", "Farenheit", "Kelvin"],
  },
  {
    modbusAddress: 102,
    name: "Body Temperature",
    unit: "°C" as UnitSymbol,
    valueRange: { min: -30, max: 120 },
    ranges: [
      { color: "red", from: -30, to: -10 },
      { color: "yellow", from: -10, to: 0 },
      { color: "green", from: 0, to: 60 },
      { color: "yellow", from: 60, to: 80 },
      { color: "red", from: 80, to: 120 },
    ],
    ticks: [
      -30, -20, -10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120,
    ],
    unit_items: ["Celcius", "Farenheit", "Kelvin"],
  },
  {
    modbusAddress: 52,
    name: "Relative humidity",
    unit: "%",
    valueRange: { min: 0, max: 100 },
    ranges: [
      { color: "red", from: 0, to: 10 },
      { color: "yellow", from: 10, to: 20 },
      { color: "green", from: 20, to: 80 },
      { color: "yellow", from: 80, to: 90 },
      { color: "red", from: 90, to: 100 },
    ],
    ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    unit_items: ["Percent"],
  },
  {
    modbusAddress: 44,
    name: "Input voltage",
    unit: "V",
    valueRange: { min: 0, max: 30 },
    ranges: [
      { color: "red", from: 0, to: 12 },
      { color: "yellow", from: 12, to: 18 },
      { color: "green", from: 18, to: 26 },
      { color: "yellow", from: 26, to: 28 },
      { color: "red", from: 28, to: 30 },
    ],
    ticks: [0, 5, 10, 15, 20, 25, 30],
    unit_items: ["Volt"],
  },
  {
    modbusAddress: 100,
    name: "Refference pressure",
    unit: "barg",
    valueRange: { min: 0, max: 210 },
    ranges: [
      { color: "red", from: -1, to: 0 },
      { color: "yellow", from: 0, to: 2 },
      { color: "green", from: 2, to: 198 },
      { color: "yellow", from: 198, to: 200 },
      { color: "red", from: 200, to: 210 },
    ],
    ticks: [0, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 210],
    unit_items: ["bar gauge", "PSI gauge", "kPa gauge"],
  },
];
