import {
  ModbusParam,
  DeviceUnitItem,
  UnitItem,
  UnitSymbol,
} from "@/lib/gen_interfaces";
//import { TimeUnit } from "chart.js";

export enum PortState {
  disconnected,
  connecting,
  connected,
}

export enum WriteAccessLevel {
  full = 3,
  medium = 2,
  normal = 1,
}

export interface ModbusStore {
  storeInit: boolean;
  portState: PortState;
  portIndex: number;
  communicationScore: number;
  deviceAddress: number;
  params: ModbusParam[];
  writeableParams: ModbusParam[];
  quickParams: ModbusParam[];
  selectedQuickParams: { [key: string]: boolean };
  deviceUnits: DeviceUnitItem[];
  guiUnits: UnitItem[];
  maxReloadCount: number;
  modbusValues: { [key: string]: number };
  modbusIsChanged: boolean;
  modbusAddrParams: { [key: string]: ModbusParam };
  modbusAddrValue: { [key: string]: boolean | number | string | null };
  modbusAddrDefaultValue: { [key: string]: boolean | number | string };
  modbusAddrLength: { [key: string]: number };
  modbusAddrReadBusy: { [key: string]: boolean };
  modbusAddrWriteBusy: { [key: string]: boolean };
  modbusAddrErrMsg: { [key: string]: string };
  modbusRefUnit: { [key: string]: UnitSymbol | "" };
  wirteAccessLevel: WriteAccessLevel;
  groups: string[];
  subgroups: { [key: string]: string[] };
  lastGroup: string;
  lastSubgroup: string;
  maxTime: number;
  isPollingMode: boolean;
  pollingTimer: number;
  pollingFlag: boolean;
  pollingDataX: string[];
  pollingDataY: { [key: string]: number[] };
  isDemoMode: boolean;
  demoTimer: number;
  demoFlag: boolean;
  demoDataX: string[];
  demoDataY: { [key: string]: number[] };
  log_toggle_zoom: null | 0 | 1 | 2;
  logDateState: number;
  log7dMaxTime: number;
  log7dMinTime: number;
  slider_7d: number[];
  slider_1m: number[];
  slider_1s: number[];
  slider_100ms: number[];
  log1mDataIsOk: boolean;
  log1mDataTime: number[];
  log1mDataRP: (number | null)[];
  log1mDataS1: (number | null)[];
  log1mDataS2: (number | null)[];
  log1mDataM12: (number | null)[];
  log1mDataSetpoint: (number | null)[];
  log1mDataV1: (number | null)[];
  log1mDataV2: (number | null)[];
  log1mDataTemp: (number | null)[];
  log1mDataBusy: boolean;
  log1sDataIsOk: boolean;
  log1sDataTime: number[];
  log1sDataRP: (number | null)[];
  log1sDataS1: (number | null)[];
  log1sDataS2: (number | null)[];
  log1sDataM12: (number | null)[];
  log1sDataSetpoint: (number | null)[];
  log1sDataV1: (number | null)[];
  log1sDataV2: (number | null)[];
  log1sDataTemp: (number | null)[];
  log1sDataBusy: boolean;
  log100msDataIsOk: boolean;
  log100msDataTime: number[];
  log100msDataRP: (number | null)[];
  log100msDataS1: (number | null)[];
  log100msDataS2: (number | null)[];
  log100msDataM12: (number | null)[];
  log100msDataSetpoint: (number | null)[];
  log100msDataV1: (number | null)[];
  log100msDataV2: (number | null)[];
  log100msDataTemp: (number | null)[];
  log100msDataBusy: boolean;
}
